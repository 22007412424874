<template>
  <div style="overflow: hidden;">
    <div class="add" style="height:auto;overflow:hidden;">
      <div class="add-searchline">
        <!-- 选择输入关键字： -->
        <el-input placeholder="请输入用户电话号或客户编号" size="small" clearable prefix-icon="el-icon-search" v-model="search" style="width: 250px;margin-right: 10px;" @clear="clearsearch">
        </el-input>
        <el-button type="primary" @click="query('')" size="small">查询</el-button>
      </div>
      <!-- <div class="add-searchline">
        选择输入客户编号：
        <el-input
          placeholder="请输入客户编号"
          clearable
          prefix-icon="el-icon-search"
          v-model="search1"
          style="width: 15%;margin-left: 20px;"
          @clear="clearsearch"
        >
        </el-input>
        <el-button type="primary" style="margin-left:20px" @click="query1">查询</el-button>
      </div> -->
    </div>
    <div class="resultmain">
      <div>
        <p><span class="cc">客户编号：</span>{{ Cust_ID }}</p>
      </div>
      <div>
        <p><span class="cc">姓名：</span>{{ Cust_Name }}</p>
      </div>
      <div>
        <p><span class="cc">话务系统所属客服：</span>{{ CustServicer }}</p>
      </div>
      <div>
        <p><span class="cc">聊天所属客服：</span>{{ result }}</p>
        <el-button v-show="result" size="mini" type="primary" @click="
            changeservepeople = true;
            getservepeoplelist();
          " style="float:right;margin-top: 11px;">修改服务人
        </el-button>
      </div>
      <div>
        <p><span class="cc">状态：</span>{{ changeStatus }}</p>
        <el-button size="mini" type="primary" @click="changeStatustc = true" style="float:right;margin-top: 11px;">修改
        </el-button>
      </div>
      <div>
        <p><span class="cc">疾病：</span>{{ Ill_Lib }}</p>
        <el-button size="mini" type="info" @click="delIll" style="float:right;margin-left:15px;margin-top: 11px;">置空
        </el-button>
        <el-button size="mini" type="primary" @click="Bind_Ill = true" style="float:right;margin-top: 11px;">修改
        </el-button>
      </div>
      <div>
        <p><span class="cc">医生：</span>{{ Doc_Name }}</p>
        <!-- <el-button size="mini" type="info" @click="delDoc" style="float:right;margin-left:15px;margin-top: 11px;">置空
        </el-button>
        <el-button size="mini" type="primary" @click="Bind_Doc = true" style="float:right;margin-top: 11px;">修改
        </el-button> -->
      </div>
    </div>
    <!-- 修改服务人 -->
    <el-dialog title="修改服务人" :visible.sync="changeservepeople" width="30%">
      <el-select v-model="changeservepeopledata" filterable clearable placeholder="请选择">
        <el-option v-for="item in servepeoplelist" :key="item.id" :label="item.name" :value="item.id">
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="(changeservepeople = false), (changeservepeopledata = '')">取 消</el-button>
        <el-button type="primary" @click="subchangeservepeople">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改状态 -->
    <el-dialog title="修改状态" :visible.sync="changeStatustc" width="30%">
      <el-select v-model="changeStatusVal" clearable placeholder="请选择">
        <el-option v-for="item in statusoptions" :key="item.id" :label="item.label" :value="item.id">
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="(changeStatustc = false), (changeStatusVal = '')">取 消</el-button>
        <el-button type="primary" @click="subchangeStatus">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 绑定疾病 -->
    <el-dialog title="绑定疾病" :visible.sync="Bind_Ill" width="30%">
      <p>
        <el-input placeholder="请输入疾病名" clearable prefix-icon="el-icon-search" v-model="searchill" style="width: 30%;" @clear="clearill">
        </el-input>
        <el-button type="primary" style="margin-left:20px" @click="queryill">查询</el-button>
      </p>
      <el-radio-group v-model="bindillcheckbox" @change="getillval">
        <el-radio style="width:100%;height: 50px;line-height: 50px;" v-for="(item, index) in bindilllist" :label="item" :key="index">{{ item.name }}</el-radio>
      </el-radio-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="Bind_Ill = false">取 消</el-button>
        <el-button type="primary" @click="subbindill">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 绑定医生 -->
    <el-dialog title="绑定医生" :visible.sync="Bind_Doc" width="30%">
      <p>
        <el-input placeholder="请输入医生名" clearable prefix-icon="el-icon-search" v-model="searchdoc" style="width: 30%;" @clear="cleardoc">
        </el-input>
        <el-button type="primary" style="margin-left:20px" @click="querydoc">查询</el-button>
      </p>
      <el-radio-group v-model="binddoccheckbox" @change="getdocval">
        <el-radio style="width:100%;height: 50px;line-height: 50px;" v-for="(item, index) in binddoclist" :label="item" :key="index">{{ item.name }}</el-radio>
      </el-radio-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="Bind_Doc = false">取 消</el-button>
        <el-button type="primary" @click="subbinddoc">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  inject: ["reload"],
  data() {
    return {
      search: "",
      search1: "",
      result: "",
      changeStatus: "",
      changeStatustc: false,
      changeStatusVal: "",
      statusoptions: [
        // { id: '1', label: '等待匹配医生' },
        // { id: '2', label: '等待下达方案' },
        // // { id: '13', label: '等待完善资料' },
        // { id: '3', label: '治疗中' },
        // { id: '4', label: '确认有效继续治疗' },
        // { id: '5', label: '确认有效继续匹配' },
        // { id: '6', label: '二次匹配' },
        // { id: '7', label: '三次匹配' },
        // { id: '8', label: '四次匹配' },
        // { id: '9', label: '未执行方案' },
        // // { id: '11', label: '有效' },
        // // { id: '12', label: '无效' },
        // { id: '10', label: '已治愈' },
        { id: 1, label: "待完善寻医信息" },
        { id: 2, label: "等待精准匹配好医生" },
        { id: 3, label: "已被医生选取" },
        { id: 4, label: "等待完善疾病信息" },
        { id: 5, label: "执行医生方案" },
        { id: 6, label: "治疗无效等待匹配其他医生治疗" },
        { id: 8, label: "确认有效继续治疗" },
        { id: 7, label: "已治愈" },
      ],
      uid: "",
      Cust_ID: "",
      Cust_Name: "",
      Ill_Lib: "",
      Doc_Name: "",
      bindillcheckbox: false,
      bindillval: "",
      Bind_Ill: false,
      searchill: "",
      bindilllist: [],
      Bind_Doc: false,
      searchdoc: "",
      binddoclist: [],
      changeStatustc: false,
      binddoccheckbox: "",
      illLibList: "",
      changeservepeople: false, //修改服务人
      changeservepeopledata: "",
      servepeoplelist: [],
      usertell: "",
      serviceId: "",
      makefulist: [],
      CustServicer: "",
    };
  },
  mounted() {
    this.uid = sessionStorage.getItem("id");
    this.getmakefulist();
  },
  methods: {
    getmakefulist() {
      this.axios.get("/ma/get_gu_ma_admin").then((res) => {
        if (res.data.code == 0) {
          this.makefulist = res.data.result;
        }
      });
    },
    query() {
      this.result = "";
      this.Cust_ID = "";
      this.Cust_Name = "";
      this.Doc_Name = "";
      this.Ill_Lib = "";
      this.changeStatus = "";
      if (this.search == "") {
        this.$message({
          message: "请输入关键字",
          type: "error",
        });
        return;
      }
      if (this.search.match(/^\d/)) {
        this.usertell = this.search;
        this.searchserverid(this.usertell);
        this.getstate(this.usertell);
      } else {
        this.axios
          .get("/gu/get_cust_info_by_custid?cust_id=" + this.search)
          .then((res) => {
            if (res.data.code == 0) {
              this.usertell = res.data.result.Tel1;
              this.searchserverid(this.usertell);
              this.getstate(this.usertell);
            }
          });
      }

      this.axios
        .post(
          "/gu/search_gu_custs",
          this.qs.stringify({ keyword: this.search, doc_id: "" })
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.Cust_ID = res.data.result[0].Cust_ID;
            this.Cust_Name = res.data.result[0].Cust_Name;
            this.axios
              .get("/gu/get_cust_info_by_custid?cust_id=" + this.Cust_ID)
              .then((res) => {
                if (res.data.code == 0) {
                  // this.changeStatus = res.data.Cust_Info.Cure_Status;
                  this.illLibList = res.data.Ill_Lib;
                  for (let i = 0; i < res.data.Ill_Lib.length; i++) {
                    const element = res.data.Ill_Lib[i];
                    this.Ill_Lib += element.name + ",";
                  }
                  this.Doc_Name = res.data.Cust_Info.Doc_Name;
                }
              });
          }
        })
        .catch((res) => {
          console.log(res);
        });
    },
    getstate(tel) {
      this.axios.get("/gu/get_gu_cust_all_by_tel?tel=" + tel).then((res) => {
        if (res.data.code == 0) {
          this.CustServicer = res.data.cust_info.Cust_Servicer;
          for (let a = 0; a < this.statusoptions.length; a++) {
            if (this.statusoptions[a].id == res.data.cust.user_status) {
              this.changeStatus = this.statusoptions[a].label;
            }
          }
        }
      });
    },
    searchserverid(tel) {
      this.axios
        .get(
          this.serveurl.serve + "/api/User/user_belong_service?userID=" + tel
        )
        .then((res) => {
          if (res.data.code == 1) {
            if (res.data.result == "") {
              this.$message({
                type: "error",
                message: "该用户未在聊天系统注册",
              });
            } else {
              this.result = res.data.result;
              this.serviceId = res.data.serviceId;
            }
          } else {
          }
        })
        .catch((res) => {
          console.log(res);
        });
    },
    query1() {
      this.axios
        .get("/gu/get_cust_info_by_custid?cust_id=" + this.search1)
        .then((res) => {
          if (res.data.code == 0) {
            this.query(res.data.result.Tel1);
          }
        });
    },
    clearsearch() {
      this.result = "";
      this.Cust_ID = "";
      this.Cust_Name = "";
      this.Doc_Name = "";
      this.Ill_Lib = "";
      this.changeStatus = "";
    },
    subchangeStatus() {
      //    this.axios
      //     .post(
      //      "/gu/update_gu_cust_info_cure_status",
      //      this.qs.stringify({
      //       x_cust_id: this.Cust_ID,
      //       cure_status: this.changeStatusVal,
      //       uid: this.uid,
      //      })
      //     )
      //     .then((res) => {
      //      if (res.data.code == 0) {
      //       this.changeStatustc = false;
      //       this.$message({
      //        message: "修改成功",
      //        type: "success",
      //       });
      //       this.changeStatusVal = "";
      //      }
      //     })
      //     .catch((res) => {
      //      console.log(res);
      //     });
      console.log({ user_status: this.changeStatusVal, tel: this.usertell });
      this.axios
        .post(
          "/gu/update_gu_cust_user_status_by_tel",
          this.qs.stringify({
            user_status: this.changeStatusVal,
            tel: this.usertell,
          })
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.changeStatustc = false;
            this.$message({
              message: "修改成功",
              type: "success",
            });
            this.changeStatus = this.changeStatusVal;
            this.changeStatusVal = "";
          }
        });
    },
    //绑定疾病
    bindIll() {
      this.Bind_Ill = true;
      this.bindillcheckbox = "";
      (this.searchill = ""), (this.bindilllist = []);
    },
    queryill() {
      this.axios
        .get("/gu/get_gu_ill_lib_like_name?ill_lib_name=" + this.searchill)
        .then((res) => {
          if (res.data.code == 0) {
            this.bindilllist = res.data.result;
          } else {
            this.$message({
              type: "error",
              message: "没有搜索到该疾病",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    subbindill() {
      if (this.bindillcheckbox == "") {
        this.$message({
          message: "请选择疾病",
          type: "error",
        });
      } else {
        this.axios
          .post(
            "/gu/insert_gu_ill_lib_cust",
            this.qs.stringify({
              cust_id: this.Cust_ID,
              ill_lib_id: this.bindillcheckbox.id,
            })
          )
          .then((res) => {
            if (res.data.code == 0) {
              this.$message({
                message: "绑定成功",
                type: "success",
              });
            } else {
              this.$message(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
        this.Ill_Lib += this.bindillcheckbox.name + ",";
        this.Bind_Ill = false;
      }
    },
    getillval() {
      // console.log(this.bindillcheckbox);
    },
    clearill() {
      this.bindilllist = [];
    },
    //绑定医生
    bindDoc() {
      this.Bind_Doc = true;
      this.binddoccheckbox = "";
      (this.searchdoc = ""), (this.binddoclist = []);
    },
    querydoc() {
      this.axios
        .post(
          "/gu/search_gu_docs",
          this.qs.stringify({ keyword: this.searchdoc, is_flag: "all" })
        )
        .then((rs) => {
          if (rs.data.code == 0) {
            this.binddoclist = rs.data.result;
          } else {
            this.$message({
              type: "error",
              message: "没有搜索到该医生",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    subbinddoc() {
      if (this.binddoccheckbox == "") {
        this.$message({
          message: "请选择医生",
          type: "error",
        });
      } else {
        this.axios
          .post(
            "/gu/update_gu_cust_info_docid",
            this.qs.stringify({
              x_cust_id: this.Cust_ID,
              doc_id: this.binddoccheckbox.id,
              uid:sessionStorage.getItem("servicer_id")
            })
          )
          .then((res) => {
            if (res.data.code == 0) {
              // this.axios
              //   .post(
              //     "/field/update_z_newseadoc_main_docid_by_custid",
              //     this.qs.stringify({
              //       doc_id: this.binddoccheckbox.id,
              //       cust_id: this.Cust_ID,
              //       // uid: sessionStorage.getItem("servicer_id"),
              //     })
              //   )
              //   .then((res) => {
              //     if (res.data.code == 0) {
              //       console.log(res);
              //     }
              //   });
              this.$message({
                type: "success",
                message: "绑定成功",
              });
            } else {
              this.$message(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
        this.Bind_Doc = false;
        this.Doc_Name = this.binddoccheckbox.name;
      }
    },
    getdocval() {
      console.log(this.binddoccheckbox);
    },
    cleardoc() {
      this.binddoclist = [];
    },
    delIll() {
      this.$confirm("此操作是解绑所有疾病, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          for (let i = 0; i < this.illLibList.length; i++) {
            const element = this.illLibList[i];
            this.axios
              .post(
                "/gu/delete_gu_ill_lib_cust",
                this.qs.stringify({
                  cust_id: this.Cust_ID,
                  ill_lib_id: element.id,
                })
              )
              .then((res) => {
                if (res.data.code == 0) {
                } else {
                  console.log(res);
                }
                if (i == this.illLibList.length - 1) {
                  this.$message({
                    type: "success",
                    message: "解绑成功",
                  });
                  this.Ill_Lib = "";
                }
              })
              .catch((res) => {
                console.log(res);
              });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消解绑",
          });
        });
    },
    delDoc() {
      this.$confirm("此操作是解绑医生, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios
            .post(
              "/gu/update_gu_cust_info_docid",
              this.qs.stringify({
                x_cust_id: this.Cust_ID,
                doc_id: "",
                uid: sessionStorage.getItem("servicer_id"),
              })
            )
            .then((rs) => {
              if (rs.data.code == 0) {
                this.$message({
                  message: "解绑成功",
                  type: "success",
                });
                this.Doc_Name = "";
              } else {
                this.$message(rs.data.result);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消解绑",
          });
        });
    },
    getservepeoplelist() {
      this.axios
        .get(this.serveurl.serve + "/api/User/get_service_user")
        .then((res) => {
          if (res.data.code == 1) {
            this.servepeoplelist = res.data.result;
          }
        });
    },
    subchangeservepeople() {
      if (this.changeservepeopledata == "") {
        this.$message({
          message: "请选择客服",
          type: "error",
        });
        return;
      }
      console.log(this.changeservepeopledata);
      console.log(this.serviceId);
      this.axios
        .post(
          this.serveurl.serve + "/api/User/change_users_service",
          this.qs.stringify({
            targetID: this.changeservepeopledata,
            userIDList: this.usertell,
            agent: sessionStorage.getItem("servicer_id"),
            fromId: this.serviceId,
          })
        )
        .then((res) => {
          if (res.data.code == 1) {
            this.$message({
              message: res.data.msg,
              type: "success",
            });
            this.axios
              .post(
                "/log/chat_login_log",
                this.qs.stringify({
                  data:
                    "【修改服务人】 " +
                    sessionStorage.getItem("id") +
                    "  ---   " +
                    sessionStorage.getItem("name") +
                    "转移得用户姓名：" +
                    this.usertell,
                })
              )
              .then((res) => {});
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
          this.removeMsgList(this.serviceId, this.usertell);
          var oldid = this.filterid(this.serviceId);
          var _cust_servicer = this.changeservepeopledata.substr(
            1,
            this.changeservepeopledata.length
          );
          console.log(_cust_servicer);
          for (var a = 0; a < this.makefulist.length; a++) {
            if (_cust_servicer == this.makefulist[a].id) {
              _cust_servicer = this.makefulist[a].servicer_id;
            }
          }
          console.log(_cust_servicer);
          //清除转移服务人时的未读
          this.axios
            .post(
              this.serveurl.serve + "/api/User/read_message",
              this.qs.stringify({
                fromID: this.usertell,
                toID: oldid,
              })
            )
            .then((res) => {
              if (res.data.code == 1) {
                console.log(res);
              } else {
                console.log(res);
              }
            })
            .catch((error) => {
              console.error(error.message);
            });
          //根据电话号查询患者的cust_id
          this.axios
            .get("/gu/get_cust_info_by_tel?tel=" + this.usertell)
            .then((res) => {
              if (res.data.code == 0) {
                var custID = res.data.Cust_ID;
                var _assigner = sessionStorage.getItem("servicer_id");
                //改变服务人同步话务系统
                this.axios
                  .post(
                    "/chat/insert_assigntable",
                    this.qs.stringify({
                      cust_id: custID,
                      assigner: _assigner,
                      cust_servicer: _cust_servicer,
                    })
                  )
                  .then((res) => {
                    if (res.data.code == 0) {
                      console.log(res);
                    } else {
                      console.log(res);
                    }
                  });
              }
            });

          this.servepeoplelist = [];
          this.changeservepeopledata = "";
          this.usertell = "";
          this.changeservepeople = false;
          this.reload();
        });
    },
    //对客服列表的id进行处理
    filterid(val) {
      val = String(val);
      if (val.substr(0, 1) == "s") {
        return val;
      } else {
        return "s" + val;
      }
    },
    //转移完服务人之后删除消息
    removeMsgList(sid, arr) {
      this.axios
        .post(
          this.serveurl.serve + "/api/MessageStore/remove",
          this.qs.stringify({ service: sid, userId: arr })
        )
        .then((res) => {
          if (res.data.code == 1) {
            console.log(res);
            console.log("移除成功");
          }
        });
    },
  },
};
</script>
<style>
.add {
  width: 100%;
  overflow: hidden;
  text-align: left;
  float: left;
}

.resultmain {
  margin-top: 20px;
  line-height: 50px;
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  font-size: 16px;
  text-align: left;
  width: 50%;
  float: left;
  overflow: hidden;
}
.resultmain div {
  padding: 8px;
  box-sizing: border-box;
  width: 100%;
  float: left;
  border-bottom: 1px solid #d2d2d2;
}
.resultmain div:last-child {
  border-bottom: none;
}
.resultmain p {
  width: 260px;
  float: left;
  margin: 0;
}
.resultmain span.cc {
  color: #409eff;
}
.changebtn {
  cursor: pointer;
  color: green;
  margin-right: 8px;
}

.borderright {
  border-right: 1px solid #d2d2d2;
}

.padding20 {
  padding: 0 5px;
}
.add-searchline {
  width: 100%;
  float: left;
  margin-bottom: 10px;
}
</style>
